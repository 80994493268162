import React, { Fragment } from 'react'
import tragiclogo from '../images/tragic-logo.png'
import  Mailchimp  from 'react-mailchimp-form' 

const footer = () => (
  <Fragment>
	<div className="footer-dark">
		<div className="row">
			<div className="col">
				<div className="newsletter">
					<h3 className="text-center">Join Our Newsletter</h3>
					<div className="input-group mb-3 newsletter-form-control">
						<label className="d-none" htmlFor="email-input-footer">Input a valid e-mail address.</label>
						<Mailchimp
							className="form-control"
							action='https://sdsportsacu.us7.list-manage.com/subscribe/post?u=f1027f9738a55e44f7939241a&amp;id=3c8b210102'
							fields={[
								{
									name: 'EMAIL',
									placeholder: 'Email Address',
									type: 'email',
									required: true
								}
							]}
							messages={
								{ 
									sending: "Sending...",
									success: "Thank you for subscribing!",
									error: "An unexpected internal error has occurred.",
									empty: "You must write an e-mail.",
									duplicate: "Too many subscribe attempts for this email address",
									button: "Sign Up"
								}
							}
						/>
					</div>
				</div>
			</div>
		</div>
		<footer>
			<div className="container">
				<div className="row contact-info-footer">
					<div className="col-sm-6 col-md-3 item text-center">
						<h3>Phone Number</h3>
						<p><a href="tel:+18582558070" className="footer-link">(858) 255-8070</a></p>
					</div>
					<div className="col-sm-6 col-md-6 item text-center">
						<h3>Office Location</h3>
						<p><a href="https://maps.app.goo.gl/XTYdiB1iJ1kCyesL7" target="_blank">4550 Kearny Villa Rd. Ste 110, San Diego CA 92123</a></p>
					</div>
					<div className="col-md-3 item text text-center">
						<h3>Email Address</h3>
						<p><a href="mailto:info@sdsportsacu.com" className="footer-link">info@sdsportsacu.com</a></p>
					</div>
				</div>
				<nav className="navbar navbar-dark navbar-expand-md navigation-clean">
					<div className="container">
					  <div className="collapse navbar-collapse show" id="navcol-2">
							<ul className="nav navbar-nav ml-auto mr-auto">
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/our-results">Our Results</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/about-us">About Us</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/faqs">FAQS</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/testimonials">Testimonials</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/case-studies">Case Studies</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/contact">Contact</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" rel="noopener noreferrer" href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=ad4fbee7-5eab-4efa-9cfa-8135ba772200" target="_blank">Appointments</a></li>
								<li className="nav-item text-center" role="presentation"><a className="nav-link" href="/privacy-policy">Privacy Policy</a></li>
							</ul>
						</div>
					</div>
				</nav>
				<p className="siteby text-center"><a href="https://tragic.media/home" className="footer-link">Website by&nbsp; <img src={tragiclogo} alt="Tragic Media" /></a></p>
			</div>
		</footer>
	</div>
  </Fragment>
)

export default footer