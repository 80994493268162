import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap'
import RenderRichText from './renderRichText'


const Header = ({ siteTitle }) => {

	const notice = useStaticQuery(graphql
		`query {
			contentfulNotice {
				entryTitle
				noticeContent {
						json
				}
				active
			}
		}`
	);

	return ( 
		<header>
			{ notice.contentfulNotice.active && <div className="row text-center notice">
				<div className="text-center m-auto"><RenderRichText data={notice.contentfulNotice.noticeContent.json} /></div>
			</div> }
			<Row className="row topBar">
				<Col className="container">
					<p className="text-right topHolder">&nbsp;
						<span className="phoneNumber d-none d-lg-inline-block d-xl-inline-block">
						<a href="tel:+18582558070" className="header-phone-number-link">(858) 255-8070</a></span>
						<span className="address d-none d-lg-inline-block d-xl-inline-block"><a href="https://maps.app.goo.gl/XTYdiB1iJ1kCyesL7" className="header-phone-number-link" target="_blank">4550 Kearny Villa Rd. Ste 110, San Diego CA 92123</a></span>
						<a href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=ad4fbee7-5eab-4efa-9cfa-8135ba772200" rel="noopener noreferrer" className="btn btn-primary appointment" type="button">Schedule an appointment</a>
					</p>
				</Col>
			</Row>
			<Navbar className="navigation-clean" expand="lg" variant="dark">
				<Container>
					<Link className="navbar-brand" to="/">San Diego Sports Accupuncture</Link>
					<Navbar.Toggle aria-controls="navcol-1" />
					<Navbar.Collapse id="navcol-1">
							<Nav as="ul" className="ml-auto">
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/our-results">Our Results</Link></li>
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/about-us">About Us</Link></li>
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/faqs">FAQS</Link></li>
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/testimonials">Testimonials</Link></li>
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/case-studies">Case Studies</Link></li>
								<li className="nav-item" role="presentation"><Link className="nav-link" activeClassName="active" to="/contact">Contact</Link></li>
								<div className="nav-meta-info d-sm-block d-md-block d-lg-none d-xl-none">
								<p>(858) 255-8070 </p>
								<p>5465 Morehouse Dr. Ste 100 <br></br>
										San Diego, CA 92121</p>
								</div>
							</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	)
}

export default Header
