import React from 'react'

const Statistics = ({data}) => {
  return (
    data.map((item, key) => 
      <div className="col-sm-6 col-md-5 col-lg-4 item statistic" key={key}>
        <div className="box text-center">
          { item.icon && <img src={ item.icon.fluid.src } alt={ item.icon.fluid.title } /> }
          <h3 className="name"><span className="stat-number">{ item.stat }</span> { item.title}</h3>
          <p className="description">{ item.details.details }</p>
        </div>
      </div>
    )
  )
}

export default Statistics