import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

class ButtonFields extends Component {
  componentDidMount() {

  }
  render() {
    const data = this.props.data

    const btn = (data) => {
      if('fields' in data) {
        return <Button href={data.fields.url['en-US'] } className={`btn btn-${data.fields.appointment['en-US'] ? 'secondary' : 'primary'} ${data.fields.title['en-US'] ? data.fields.title['en-US'] : ''}`} target="_blank">{ data.fields.title['en-US'] }</Button>
      }
      else { 
        return data.map((item, count) => {
          if(item.url.indexOf('://') > 0 || item.url.indexOf('//') === 0 && item.url.indexOf("sdsportsacu.com") > -1) {
            return <Button href={item.url} className={`btn btn-${item.appointment ? 'secondary' : 'primary'} ${item.title ? item.title : ''}`} key={count} target="_blank">{ item.title }</Button>
          }else {
            return <Button href={item.url} className={`btn btn-${item.appointment ? 'secondary' : 'primary'} ${item.title ? item.title : ''}`} key={count}>{ item.title }</Button>
          }
        })
      }
    }
    return (
      <div className="text-center button-holder">
        {btn(data) }
      </div>
    )
  }
}

export default ButtonFields