import React from 'react'
import Testimonials from '../testimonials'
import CaseStudies from '../caseStudies'
import Statistics from '../statistics'
import ButtonFields from '../buttonFields'
import RenderRichText from '../renderRichText'

const inlineSingleCallOut = ({data}) => {
	console.log('inline', data)
	return (
		<div className={`inline-content single-callout${data.backgroundColor['en-US'] === 'Dark' ? 'dark' : data.backgroundColor['en-US'] === 'Red' ? 'red' : ''}`}>
			<div className="row">
				<div className="col-md-12">
					<h2 className="text-center">{ data.title['en-US'] }</h2>

					{ data.calloutText &&  
						<div className="single-callout-richtext">
							<RenderRichText data={data.calloutText['en-US']} imgLink={data.imageLink} />
						</div>
					}
					{ data.testimonials && data.testimonials.length > 1 && 
						<div className="card-group testimonials">
							<Testimonials data={data.testimonials} />
						</div>
					} 
					{ data.caseStudies && 
						<div className="article-list case-studies">
							<div className="container">
								<div className="row articles">
									<CaseStudies data={data.caseStudies} /> 
								</div>
							</div>
						</div>
					}
					{ data.statistics && 
						<div className="features-boxed statistics">
								<div className="container">
										<div className="row justify-content-center features">
												<Statistics data={data.statistics} />
										</div>
								</div>
						</div> 
					}
					{ data.button && 
						<div className="text-center">
								<ButtonFields data={data.button['en-US'][0]} />
						</div>
					} 
				</div>
			</div>
	</div>
	)
}

export default inlineSingleCallOut